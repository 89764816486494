import SimpleSchema from 'simpl-schema';
import { check } from 'meteor/check';

export const CategorySchema = new SimpleSchema(
  {
    name: String,
    parentId: { type: String, regEx: SimpleSchema.RegEx.Id, optional: true },
  },
  { check }
);
