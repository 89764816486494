<template>
  <PageLayout>
    <template #header>
      <div class="d-flex w-100 justify-content-between">
        <h1>{{ $t('routes.suppliersPurchaseManagement') }}</h1>
        <Button
          type="secondary"
          :style="{ height: '2rem', paddingTop: '0', paddingBottom: '0' }"
          @click="onSettingsClick"
        >
          <div class="d-flex gap-1 align-content-center">
            <SettingsIcon />
            <div class="align-self-center">{{ $t('terms.settings') }}</div>
          </div>
        </Button>
      </div>
    </template>
    <template #dashboard>
      <SuppliersPurchasesCarousel />
    </template>
    <SuppliersPurchaseManagement
      :date-ranges-state="dateRanges"
      :suppliers-purchase-management-data="suppliersPurchaseManagementData"
      :suppliers-purchase-management-loading="suppliersPurchaseManagementLoading"
      @on-row-click="routeToSupplier"
      @on-export-products="onExportProducts"
      @on-date-range-update="onDateRangeUpdate"
    />
  </PageLayout>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';

import { useCurrency } from '@/locale/useCurrency';

import { SettingsIcon } from '@/assets/icons';
import { PageLayout, Button } from '@/modules/core/components';
import { useTenancy } from '@/modules/auth';

import {
  useSuppliersPurchaseManagement,
  useExportAllProducts,
} from './compositions/overview/useSuppliersPurchaseManagement';
import SuppliersPurchaseManagement from './components/suppliersPurchaseManagement/SuppliersPurchaseManagement';
import SuppliersPurchasesCarousel from './components/suppliersPurchaseManagement/SuppliersPurchasesCarousel';
import { getDateRangeStringFromDates, getISODateFromDateTime } from './purchaseManagementFormatters';
import { exportAllProducts } from './excel';
import { validateMainDateEntryRouteParam, validateRestDateEntriesRouteParams } from './tools/validators';

export default {
  components: {
    Button,
    PageLayout,
    SettingsIcon,
    SuppliersPurchaseManagement,
    SuppliersPurchasesCarousel,
  },
  setup() {
    const root = getCurrentInstance().proxy;

    const { currencyFormat } = useCurrency();

    const [startDate, endDate] = validateMainDateEntryRouteParam(root);
    validateRestDateEntriesRouteParams(root);

    const fromDate = ref(startDate);
    const toDate = ref(endDate);

    const activeTab = ref(0);
    const dateRanges = ref([{ startDate: fromDate.value, endDate: toDate.value }]);
    const triggerExportProducts = ref(false);
    const exportProductsLoading = ref(null);

    const dateRange = computed(() => getDateRangeStringFromDates(fromDate.value, toDate.value));

    const { currentTenant } = useTenancy();
    const { suppliersPurchaseManagementData, loading: suppliersPurchaseManagementLoading } =
      useSuppliersPurchaseManagement(
        computed(() => {
          const firstStartDate = getISODateFromDateTime(dateRanges.value[0].startDate);
          const firstEndDate = getISODateFromDateTime(dateRanges.value[0].endDate);
          const getSecondTimePeriod = dateRanges.value.length > 1;
          const getThreeTimePeriods = dateRanges.value.length > 2;

          return {
            tenantId: currentTenant.value.id,
            fromDate: firstStartDate,
            toDate: firstEndDate,
            fromDate1: getSecondTimePeriod ? getISODateFromDateTime(dateRanges.value[1].startDate) : undefined,
            toDate1: getSecondTimePeriod ? getISODateFromDateTime(dateRanges.value[1].endDate) : undefined,
            fromDate2: getThreeTimePeriods ? getISODateFromDateTime(dateRanges.value[2].startDate) : undefined,
            toDate2: getThreeTimePeriods ? getISODateFromDateTime(dateRanges.value[2].endDate) : undefined,
            getSecondTimePeriod,
            getThreeTimePeriods,
          };
        })
      );
    const { onResult } = useExportAllProducts(
      computed(() => ({
        tenantId: currentTenant.value.id,
        fromDate: getISODateFromDateTime(fromDate.value),
        toDate: getISODateFromDateTime(toDate.value),
        fromDateString: getISODateFromDateTime(fromDate.value),
        toDateString: getISODateFromDateTime(toDate.value),
        triggerExportProducts: triggerExportProducts.value,
      }))
    );

    const routeToSupplier = ({ supplier }) => {
      root.$router.push({
        name: 'supplierProductsPurchaseManagement',
        params: { supplierId: supplier.id },
        query: root.$route.query,
      });
    };

    const onExportProducts = () => {
      exportProductsLoading.value = root.$loading({
        text: root.$t('terms.supplierTerms.supplierTermTable.exportAllProductsLoading'),
      });
      triggerExportProducts.value = true;
    };

    onResult(({ data }) => {
      try {
        exportAllProducts({
          productsSummary: data.productPurchaseSummary?.nodes,
          suppliersSummary: data.supplierPurchaseSummary?.groupBySupplier?.nodes,
          dateRange: dateRange.value,
          currencyFormat: currencyFormat.value,
        });
      } catch (e) {
        throw new Error(e);
      } finally {
        exportProductsLoading.value.close();
        triggerExportProducts.value = false;
      }
    });

    const onDateRangeUpdate = (updatedDateRange) => {
      dateRanges.value = updatedDateRange.length
        ? updatedDateRange
        : [{ startDate: fromDate.value, endDate: toDate.value }];
    };

    const onSettingsClick = () => {
      root.$router.push({ name: 'suppliersSettings' });
    };

    return {
      suppliersPurchaseManagementData,
      suppliersPurchaseManagementLoading,
      activeTab,
      dateRanges,
      routeToSupplier,
      onSettingsClick,
      onExportProducts,
      onDateRangeUpdate,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
.dashboard ::v-deep {
  height: $page-layout-dashboard-hight + 3rem;

  .VueCarousel-wrapper {
    flex: 1;
  }

  .card {
    height: $page-layout-dashboard-hight;
  }
}
</style>
