import { gql } from '@apollo/client/core';

export const PRODUCT_QUERY = gql`
  query product($productId: ID!) {
    product(id: $productId) {
      id
      name
      sku
      business {
        id
        name
      }
      alias
      aliases {
        id
        name
        sku
        alias
      }
      references {
        name
        sku
      }
      similarProducts {
        id
        name
        sku
        business {
          id
          name
        }
        references {
          name
          sku
        }
      }
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query products($businessId: ID) {
    productsOldNew(businessId: $businessId) {
      nodes {
        id
        name
        sku
        alias
        aliases {
          id
        }
      }
    }
  }
`;

export const PRODUCTS_QUERY_NEW = gql`
  query products($businessId: ID) {
    productsOldNew(businessId: $businessId) {
      nodes {
        id
        name
        sku
        alias
        aliases {
          id
        }
      }
    }
  }
`;

export const SET_PRODUCT_ALIAS_MUTATION = gql`
  mutation setProductAlias($productId: ID!, $productAliasId: ID!) {
    setProductAlias(id: $productId, alias: $productAliasId)
  }
`;

export const SET_PRODUCT_PRIMARY_ALIAS_MUTATION = gql`
  mutation setProductPrimaryAlias($productId: ID!) {
    setProductPrimaryAlias(id: $productId)
  }
`;

export const REMOVE_PRODUCT_ALIAS_MUTATION = gql`
  mutation removeProductAlias($productId: ID!) {
    removeProductAlias(id: $productId)
  }
`;

export const PRODUCT_ORDERS_QUERY = gql`
  query productOrders($productId: ID!, $limit: Int) {
    productOrders(productId: $productId, limit: $limit) {
      amount
      billDiscount
      billPrice
      date
      discount
      price
      quantity
      document {
        id
      }
    }
  }
`;

export const PRODUCT_PURCHASE_DATA_QUERY = gql`
  query productPurchaseData($productId: ID!) {
    productPurchaseData(productId: $productId) {
      byMonths {
        data {
          ...fields
        }
        month
      }
      currentMonth {
        ...fields
      }
      currentYear {
        ...fields
      }
      ever {
        ...fields
      }
      firstPurchase
      lastPurchase
      monthlyAverage {
        ...fields
      }
      previousMonth {
        ...fields
      }
      previousYear {
        ...fields
      }
      yearlyEstimation {
        ...fields
      }
    }
  }

  fragment fields on PurchaseData {
    amount
    deliveries
    quantity
  }
`;
