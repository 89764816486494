import SimpleSchema from 'simpl-schema';

const createRecursiveSchema = (schemaDef = {}, { key = 'subField', rules = {} } = {}, depth = 0) =>
  depth > 0
    ? new SimpleSchema({ [key]: { type: createRecursiveSchema(schemaDef, key, depth - 1), ...rules } }).extend(
        schemaDef
      )
    : new SimpleSchema(schemaDef);

export const unitsOfMeasure = {
  mass: ['kg', 'g'],
  volume: ['l', 'ml'],
  generic: ['bottle', 'unit', 'package'],
};

const allUnits = Object.values(unitsOfMeasure).reduce((total, uoms) => total.concat(uoms));

const BOMSchema = createRecursiveSchema(
  {
    quantity: { type: Number },
    uom: { type: String, allowedValues: allUnits },
  },
  { key: 'bom', rules: { optional: true } },
  1
);

export const ProductSchema = new SimpleSchema(
  {
    name: { type: String, required: true },
    businessId: { type: SimpleSchema.RegEx.Id, required: true },
    sku: String,
    gtin: String,
    categoryId: SimpleSchema.RegEx.Id,
    uom: { type: String, allowedValues: allUnits },
    bom: BOMSchema,
  },
  { requiredByDefault: false }
);
