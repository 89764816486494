import { gql } from '@apollo/client/core';

export const TERMS_NEW_QUERY = gql`
  query terms($productIds: [ID!]) {
    termsNew(productIds: $productIds) {
      nodes {
        active
        id
        productId
        businessId
        business {
          id
          name
        }
        type
        pricingMethod
        price
        priceList {
          name
          prices(productIds: $productIds) {
            data {
              date
              price
            }
          }
        }
        priceIndexId
        priceIndexItemId
        priceIndexOffset
        priceIndexCommission
        rewardRealizationTime
        rewardType
        rewardValue
        conditionType
        conditionValue
        conditionLimit
        fromDate
        toDate
      }
    }
  }
`;

export const TERMS_QUERY = gql`
  query terms($productIds: [ID!]) {
    terms(productIds: $productIds) {
      active
      id
      productId
      businessId
      business {
        id
        name
      }
      type
      pricingMethod
      price
      priceList {
        name
        prices(productIds: $productIds) {
          data {
            date
            price
          }
        }
      }
      priceIndexId
      priceIndexItemId
      priceIndexOffset
      priceIndexCommission
      rewardRealizationTime
      rewardType
      rewardValue
      conditionType
      conditionValue
      conditionLimit
      fromDate
      toDate
    }
  }
`;
