<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('routes.suppliersPurchaseManagement') }}</h1>
      <p class="fs-bold">{{ supplier.name }}</p>
    </template>
    <template #dashboard>
      <SupplierProductsDashboard :supplier="supplier" />
    </template>
    <SupplierProductsPurchaseManagement
      :date-ranges-state="dateRanges"
      :previous-period-dates="previousPeriodDates"
      :supplier="supplier"
      :supplier-products-overview="tableData"
      :loading="!loadingData"
      @on-purchase-list-status-update="onPurchaseListStatusUpdate"
      @on-date-range-update="onDateRangeUpdate"
    />
  </PageLayout>
</template>

<script>
import { computed, ref, watch, getCurrentInstance } from 'vue';

import { PageLayout } from '@/modules/core/components';
import { useSupplier } from '@/modules/suppliers/compositions/supplier';
import { useTenancy, useUserPermissions } from '@/modules/auth';

import { useSupplierOverview } from './compositions/overview/useSupplierOverview';
import { useUpdatePurchaseListItem } from './compositions/purchaseList/useUpdatePurchaseListItem';
import { getPreviousPeriodDates } from './purchaseManagement';
import SupplierProductsPurchaseManagement from './components/supplierProductsPurchaseManagement/SupplierProductsPurchaseManagement';
import SupplierProductsDashboard from './components/supplierProductsPurchaseManagement/SupplierProductsDashboard';
import { validateMainDateEntryRouteParam, validateRestDateEntriesRouteParams } from './tools/validators';
import { getISODateFromDateTime } from './purchaseManagementFormatters';
import { USER_NEW_PERMISSIONS_LIST } from '@/permissions';
const AVAILABLE_TABS = ['terms', 'contracts'];

export default {
  components: {
    PageLayout,
    SupplierProductsPurchaseManagement,
    SupplierProductsDashboard,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const hasPermissionForPaymentTerms = isUserPermittedForActiveTenant(
      USER_NEW_PERMISSIONS_LIST.PURCHASE_PAYMENT_TERMS
    );
    const hasPermissionForViewingOrderTerms = isUserPermittedForActiveTenant(USER_NEW_PERMISSIONS_LIST.ORDER_PROCESS);

    const [startDate, endDate] = validateMainDateEntryRouteParam(root);
    validateRestDateEntriesRouteParams(root);

    const dateRanges = ref([{ startDate, endDate }]);
    const activeTab = ref(0);

    const supplierId = computed(() => root.$route.params.supplierId);

    const { currentTenant } = useTenancy();
    const { supplier } = useSupplier(supplierId);
    const previousPeriodDates = computed(() =>
      getPreviousPeriodDates(dateRanges.value[0].endDate, dateRanges.value[0].startDate)
    );

    const {
      supplierProductOverviewForDefaultTable,
      loading: defaultTableLoading,
      refetch,
    } = useSupplierOverview(
      computed(() => ({
        supplierId: supplierId.value,
        tenantId: currentTenant.value.id,
        fromDate: getISODateFromDateTime(dateRanges.value[0].startDate),
        toDate: getISODateFromDateTime(dateRanges.value[0].endDate),
        previousPeriodFromDate: previousPeriodDates.value.previousPeriodFromDate,
        previousPeriodToDate: previousPeriodDates.value.previousPeriodToDate,
        getThreeTimePeriods: dateRanges.value.length > 2,
        getFirstComparisonDate: dateRanges.value.length > 1,
        fromDate1: dateRanges.value.length > 1 ? getISODateFromDateTime(dateRanges.value[1].startDate) : undefined,
        toDate1: dateRanges.value.length > 1 ? getISODateFromDateTime(dateRanges.value[1].endDate) : undefined,
        fromDate2: dateRanges.value.length > 2 ? getISODateFromDateTime(dateRanges.value[2].startDate) : undefined,
        toDate2: dateRanges.value.length > 2 ? getISODateFromDateTime(dateRanges.value[2].endDate) : undefined,
      }))
    );

    const { updatePurchaseListItem } = useUpdatePurchaseListItem(refetch);

    watch(
      () => root.$route.query.tab,
      (tabName) => {
        const tabIndex = AVAILABLE_TABS.indexOf(tabName);
        if (tabIndex !== -1) activeTab.value = tabIndex;
      },
      { immediate: true }
    );

    const onPurchaseListStatusUpdate = ({ isRecommended, productId }) => {
      updatePurchaseListItem({
        isRecommended,
        productId,
        businessId: currentTenant.value.id,
        supplierId: supplierId.value,
      });
    };

    const onDateRangeUpdate = (updatedDateRange) => {
      dateRanges.value = updatedDateRange.length ? updatedDateRange : [{ startDate, endDate }];
    };

    return {
      supplier,
      currentTenant,
      previousPeriodDates,
      activeTab,
      hasPermissionForPaymentTerms,
      hasPermissionForViewingOrderTerms,
      tableData: supplierProductOverviewForDefaultTable,
      loadingData: defaultTableLoading,
      selectedAgreementId: ref(null),
      startDate,
      endDate,
      onPurchaseListStatusUpdate,
      dateRanges,
      onDateRangeUpdate,
    };
  },
  computed: {
    tabs() {
      return [
        {
          text: this.$t('terms.supplierTerms.tabs.terms'),
        },
        {
          text: this.$t('terms.supplierTerms.tabs.generalTerms'),
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard ::v-deep .card {
  height: 13.375rem;
}
.terms {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
